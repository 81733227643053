// extracted by mini-css-extract-plugin
export var address = "account-details-module--address--a9939";
export var billingDetails = "account-details-module--billingDetails--cf2f5";
export var billingDetails1 = "account-details-module--billingDetails1--4120f";
export var billingDetails4 = "account-details-module--billingDetails4--bf18c";
export var cta = "account-details-module--cta--20f9e";
export var cta1 = "account-details-module--cta1--cd54d";
export var darkButton = "account-details-module--darkButton--99967";
export var darkButton1 = "account-details-module--darkButton1--15acd";
export var darkButton2 = "account-details-module--darkButton2--dda23";
export var fullName = "account-details-module--fullName--1819f";
export var fullNameContainer = "account-details-module--fullNameContainer--e423e";
export var helperMessage = "account-details-module--helperMessage--6dd83";
export var helperMessage1 = "account-details-module--helperMessage1--a0685";
export var inputCountry = "account-details-module--inputCountry--22033";
export var inputFirstname = "account-details-module--inputFirstname--b851d";
export var inputNumberField = "account-details-module--inputNumberField--e806b";
export var inputSelect = "account-details-module--inputSelect--07620";
export var inputTextField = "account-details-module--inputTextField--123ea";
export var inputTextField2 = "account-details-module--inputTextField2--faa24";
export var label = "account-details-module--label--0db8c";
export var label10 = "account-details-module--label10--daba2";
export var personalDetails = "account-details-module--personalDetails--8bf1b";
export var separator = "account-details-module--separator--ec4c2";
export var settingsPanel = "account-details-module--settingsPanel--d0113";
export var shoppingCartLayout = "account-details-module--shoppingCartLayout--26a87";
export var span = "account-details-module--span--e871a";
export var summarySlot = "account-details-module--summarySlot--86d0a";