import * as React from "react";
import { FunctionComponent, useMemo } from "react";
import CSS, { Property } from "csstype";
import * as styles from "./personal-info-container.module.css";
import { gql, useMutation, useQuery } from "@apollo/client";
import useAuth, { User } from "../useAuth";
import { getAllCountries } from "../hooks/getAllCountries";
import { Input, Select, useToast, Text } from "@chakra-ui/react";
import { GET_CUSTOMER } from "../../queries/getCustomer";
import UPDATE_PROFILE from "../../queries/updateProfile";


type PersonalInfoContainerType = {
  /** Style props */
  propBackgroundColor?: Property.BackgroundColor;
  propBackgroundColor1?: Property.BackgroundColor;
  propBackgroundColor2?: Property.BackgroundColor;
  propBorder?: Property.Border;
  propBoxSizing?: Property.BoxSizing;
  propBackgroundColor3?: Property.BackgroundColor;
  propBackgroundColor4?: Property.BackgroundColor;
  propBackgroundColor5?: Property.BackgroundColor;
  propBackgroundColor6?: Property.BackgroundColor;
  propBackgroundColor7?: Property.BackgroundColor;
  propBackgroundColor8?: Property.BackgroundColor;
  propBorder1?: Property.Border;
  propBoxSizing1?: Property.BoxSizing;
  propBackgroundColor9?: Property.BackgroundColor;
  propBackgroundColor10?: Property.BackgroundColor;
};

const PersonalInfoContainer: FunctionComponent<PersonalInfoContainerType> = ({
  propBackgroundColor,
  propBackgroundColor1,
  propBackgroundColor2,
  propBorder,
  propBoxSizing,
  propBackgroundColor3,
  propBackgroundColor4,
  propBackgroundColor5,
  propBackgroundColor6,
  propBackgroundColor7,
  propBackgroundColor8,
  propBorder1,
  propBoxSizing1,
  propBackgroundColor9,
  propBackgroundColor10,
}) => {
  const inputFirstnameStyle: CSS.Properties = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor,
    };
  }, [propBackgroundColor]);

  const inputFirstname1Style: CSS.Properties = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor1,
    };
  }, [propBackgroundColor1]);

  const inputStyle: CSS.Properties = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor2,
    };
  }, [propBackgroundColor2]);

  const separatorStyle: CSS.Properties = useMemo(() => {
    return {
      border: propBorder,
      boxSizing: propBoxSizing,
    };
  }, [propBorder, propBoxSizing]);

  const inputCountryStyle: CSS.Properties = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor3,
    };
  }, [propBackgroundColor3]);

  const inputFirstname2Style: CSS.Properties = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor4,
    };
  }, [propBackgroundColor4]);

  const inputFirstname3Style: CSS.Properties = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor5,
    };
  }, [propBackgroundColor5]);

  const inputFirstname4Style: CSS.Properties = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor6,
    };
  }, [propBackgroundColor6]);

  const inputFirstname5Style: CSS.Properties = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor7,
    };
  }, [propBackgroundColor7]);

  const inputFirstname6Style: CSS.Properties = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor8,
    };
  }, [propBackgroundColor8]);

  const separator1Style: CSS.Properties = useMemo(() => {
    return {
      border: propBorder1,
      boxSizing: propBoxSizing1,
    };
  }, [propBorder1, propBoxSizing1]);

  const inputFirstname7Style: CSS.Properties = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor9,
    };
  }, [propBackgroundColor9]);

  const inputFirstname8Style: CSS.Properties = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor10,
    };
  }, [propBackgroundColor10]);

  var regionNames = new Intl.DisplayNames(["EN"], { type: "region" });

  const toast = useToast();
  var allCountries = getAllCountries();

  const { user } = useAuth();
  const { id, email } = user as User;

  const [updateProfile, { data, loading, error }] = useMutation(
    UPDATE_PROFILE,
    {
      onCompleted: () => {
        toast({
          title: "Your profile was successfully updated!",
          status: "success",
        });
        // window.location.reload();
      },
      onError: () => {
        toast({
          title: "Error",
          description:
            "There was an error updating you profile. Please try again later.",
          status: "error",
        });
      },
    }
  );

  const customer = useQuery(GET_CUSTOMER, {
    variables: { id },
  });

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data: any = new FormData(event.currentTarget);
    const values = Object.fromEntries(data);

    updateProfile({
      variables: { id, ...values },
    }).catch((error) => {
      console.error(error);
    });
  }

  return (
    <form
      method="post"
      onSubmit={handleSubmit}
      className={styles.personalDetails}
    >
      <div className={styles.address}>
        <div className={styles.nameLastName}>
          <div className={styles.inputTextField}>
            <div className={styles.label}>
              <Text fontWeight={700} className={styles.firstNameContainer}>
                <span>{`First name `}</span>
                <span className={styles.span}>*</span>
              </Text>
            </div>
            <input
              className={styles.inputFirstname}
              type="text"
              defaultValue={customer.data?.customer.billing.firstName || ""}
              placeholder={customer.data?.customer.billing.firstName || ""}
              name="firstName"
              required
              style={inputFirstnameStyle}
            />
            <div className={styles.helperMessage}>
              <Text className={styles.helperMessage1}>Helper message</Text>
            </div>
          </div>
          <div className={styles.inputTextField}>
            <div className={styles.label}>
              <Text fontWeight={700} className={styles.firstNameContainer}>
                <span>{`Last name `}</span>
                <span className={styles.span}>*</span>
              </Text>
            </div>
            <input
              className={styles.inputFirstname}
              type="text"
              required
              defaultValue={customer.data?.customer.billing.lastName || ""}
              placeholder={customer.data?.customer.billing.lastName || ""}
              name="lastName"
              style={inputFirstname1Style}
            />
            <div className={styles.helperMessage}>
              <Text className={styles.helperMessage1}>Helper message</Text>
            </div>
          </div>
        </div>
        <div className={styles.inputNumberField}>
          <div className={styles.inputTextField3}>
            <div className={styles.label}>
              <Text fontWeight={700} className={styles.firstNameContainer}>
                Company name (optional)
              </Text>
            </div>
            <input
              className={styles.inputFirstname}
              type="text"
              defaultValue={customer.data?.customer.billing.company || ""}
              placeholder={customer.data?.customer.billing.company || ""}
              name="company"
              style={inputStyle}
            />
          </div>
        </div>
      </div>
      <div className={styles.separator} style={separatorStyle} />
      <div className={styles.address}>
        <div className={styles.inputSelect}>
          <div className={styles.label}>
            <Text fontWeight={700} className={styles.firstNameContainer}>
              <span>{`Country / Region `}</span>
              <span className={styles.span}>*</span>
            </Text>
          </div>
          <Select
            variant="onstyled"
            className={styles.inputCountry}
            id="country"
            name="country"
          >
            {allCountries.map((item: any) => {
              return (
                <option value={item.countryCode}>
                  {regionNames.of(item.countryCode)}
                </option>
              );
            })}
          </Select>
          <div className={styles.helperMessage}>
            <Text className={styles.helperMessage1}>Helper message</Text>
          </div>
        </div>
        <div className={styles.inputNumberField}>
          <div className={styles.inputTextField3}>
            <div className={styles.label}>
              <Text fontWeight={700} className={styles.firstNameContainer}>
                <span>{`Street address `}</span>
                <span className={styles.span}>*</span>
              </Text>
            </div>
            <input
              className={styles.inputFirstname}
              type="text"
              required
              defaultValue={customer.data?.customer.billing.address1 || ""}
              placeholder={customer.data?.customer.billing.address1 || ""}
              name="address1"
              style={inputFirstname2Style}
            />
            <div className={styles.helperMessage}>
              <Text className={styles.helperMessage1}>Helper message</Text>
            </div>
          </div>
        </div>
        <div className={styles.inputNumberField}>
          <div className={styles.inputTextField3}>
            <div className={styles.label}>
              <Text fontWeight={700} className={styles.firstNameContainer}>
                Unit / Suite / Apartment (optional)
              </Text>
            </div>
            <input
              className={styles.inputFirstname}
              type="text"
              style={inputFirstname3Style}
            />
            <div className={styles.helperMessage}>
              <Text className={styles.helperMessage1}>Helper message</Text>
            </div>
          </div>
        </div>
        <div className={styles.inputNumberField}>
          <div className={styles.inputTextField3}>
            <div className={styles.label}>
              <Text fontWeight={700} className={styles.firstNameContainer}>
                <span>{`Town / City `}</span>
                <span className={styles.span}>*</span>
              </Text>
            </div>
            <input
              className={styles.inputFirstname}
              type="text"
              required
              defaultValue={customer.data?.customer.billing.city || ""}
              placeholder={customer.data?.customer.billing.city || ""}
              name="city"
              style={inputFirstname4Style}
            />
            <div className={styles.helperMessage}>
              <Text className={styles.helperMessage1}>Helper message</Text>
            </div>
          </div>
        </div>
        <div className={styles.inputNumberField}>
          <div className={styles.inputTextField3}>
            <div className={styles.label}>
              <Text fontWeight={700} className={styles.firstNameContainer}>
                <span>{`State `}</span>
                <span className={styles.span}>*</span>
              </Text>
            </div>
            <input
              className={styles.inputFirstname}
              type="text"
              required
              defaultValue={customer.data?.customer.billing.state || "" }
              placeholder={customer.data?.customer.billing.state || "" }
              style={inputFirstname5Style}
            />
            <div className={styles.helperMessage}>
              <Text className={styles.helperMessage1}>Helper message</Text>
            </div>
          </div>
        </div>
        <div className={styles.inputNumberField}>
          <div className={styles.inputTextField3}>
            <div className={styles.label}>
              <Text fontWeight={700} className={styles.firstNameContainer}>
                <span>{`Postcode / ZIP `}</span>
                <span className={styles.span}>*</span>
              </Text>
            </div>
            <input
              className={styles.inputFirstname}
              type="number"
              required
              defaultValue={customer.data?.customer.billing.postcode || ""}
              placeholder={customer.data?.customer.billing.postcode || ""}
              name="postcode"
              style={inputFirstname6Style}
            />
            <div className={styles.helperMessage}>
              <Text className={styles.helperMessage1}>Helper message</Text>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.separator} style={separator1Style} />
      <div className={styles.address}>
        <div className={styles.inputNumberField}>
          <div className={styles.inputTextField3}>
            <div className={styles.label}>
              <Text fontWeight={700} className={styles.firstNameContainer}>
                <span>{`Phone `}</span>
                <span className={styles.span}>*</span>
              </Text>
            </div>
            <input
              className={styles.inputFirstname}
              type="number"
              required
              defaultValue={customer.data?.customer.billing.phone || ""}
              placeholder={customer.data?.customer.billing.phone || ""}
              name="phone"
              style={inputFirstname7Style}
            />
            <div className={styles.helperMessage}>
              <Text className={styles.helperMessage1}>Helper message</Text>
            </div>
          </div>
        </div>
        <div className={styles.inputNumberField}>
          <div className={styles.inputTextField3}>
            <div className={styles.label}>
              <Text fontWeight={700} className={styles.firstNameContainer}>
                <span>{`Email address `}</span>
                <span className={styles.span}>*</span>
              </Text>
            </div>
            <input
              className={styles.inputFirstname}
              type="email"
              required
              defaultValue={email || ""}
              placeholder={email || ""}
              name="email"
              style={inputFirstname8Style}
            />
            <div className={styles.helperMessage}>
              <Text className={styles.helperMessage1}>Helper message</Text>
            </div>
          </div>
        </div>
        <div className={styles.cta}>
          <div className={styles.cta1}>
            <button type="submit" className={styles.darkButton2}>
              <Text fontWeight={700} className={styles.label10}>{loading ? "Loading..." : "SAVE" }</Text>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PersonalInfoContainer;
