import * as styles from "../../pages/account-settings.module.css";

import { Button, Link } from "@chakra-ui/react";
import LogOut from "../Logout/LogOut";
import React from 'react'

export default function OrderHistoryButton() {
    return (
        <>
            <div className={styles.topCommands}>
                <Link href="/order-history" _hover={{color: "white", textDecoration: "none"}}>
                    <Button border="1px solid black" borderRadius="25px" background="transparent" color="black" textTransform="uppercase" fontWeight="700" _hover={{background: "black", color: "#fff"}}>
                        <b>Shopping History</b>
                        {/* <img
                            className={styles.iconRegular}
                            alt=""
                            src="/icon--regular15.svg"
                        /> */}
                    </Button>
                </Link>
            </div>
            <LogOut />
        </>
  )
}
