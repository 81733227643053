import { gql } from "@apollo/client";

const UPDATE_PROFILE = gql`
  mutation updateProfile(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $password: String
    $phoneNumber: String
    $medicalProfessionalName: String
    $profession: String
    $specialty: String
    $medicalLicenceNumber: String
    $licenceExpirationDate: String
    $preferredCommunication: String
  ) {
    updateCustomer(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        billing: { phone: $phone }
        password: $password
        phoneNumber: $phoneNumber
        medicalProfessionalName: $medicalProfessionalName
        profession: $profession
        specialty: $specialty
        medicalLicenceNumber: $medicalLicenceNumber
        licenceExpirationDate: $licenceExpirationDate
        preferredCommunication: $preferredCommunication
      }
    ) {
      customer {
        firstName
        email
        id
        lastName
        billing {
          phone
        }
      }
    }
  }
`;

export default UPDATE_PROFILE;
