import * as React from "react";

import { Box, Button, useToast } from "@chakra-ui/react";
import { gql, useMutation } from "@apollo/client";

import { GET_USER } from "../hooks/useAuth";
import * as styles from "../../pages/account-settings.module.css";
import { navigate } from "gatsby";
import { useEffect } from "react";

const LOG_OUT = gql`
  mutation logOut {
    logout(input: {}) {
      status
    }
  }
`;

const LogOut:React.FC = () => {
  const toast = useToast;
  const [logOut, { called, loading, error, data }] = useMutation(LOG_OUT, {
    refetchQueries: [
      { query: GET_USER }
    ],
  });
  const loggedOut = Boolean(data?.logout?.status);

    function handleClick() {
       logOut()
       navigate('/sign-in');
  }

    return (
      <Button onClick={() => handleClick()} border="1px solid black" borderRadius="25px" background="transparent" color="black" textTransform="uppercase" fontWeight="700" _hover={{background: "black", color: "#fff"}}>
         Log Out
      </Button>
  );
}

export default LogOut