import React, { FunctionComponent } from "react";
import * as styles from "./account-details.module.css";
import PersonalInfoContainer from "./personal-info-container";
import { Text } from "@chakra-ui/react";


const BillingDetails: FunctionComponent = () => {

  return (
    <div className={styles.settingsPanel}>
      <div className={styles.summarySlot}>
        <div className={styles.billingDetails4}>
          <Text as="h2" fontWeight={700} className={styles.billingDetails1}>Billing Details</Text>
          <PersonalInfoContainer
            propBackgroundColor="transparent"
            propBackgroundColor1="transparent"
            propBackgroundColor2="transparent"
            propBorder="1px solid var(--gray100)"
            propBoxSizing="border-box"
            propBackgroundColor4="transparent"
            propBackgroundColor5="transparent"
            propBackgroundColor6="transparent"
            propBackgroundColor7="transparent"
            propBackgroundColor8="transparent"
            propBorder1="1px solid var(--gray100)"
            propBoxSizing1="border-box"
            propBackgroundColor9="transparent"
            propBackgroundColor10="transparent"
          />
          
        </div>
      </div>
    </div>
    
  );
};

export default BillingDetails;
