import * as React from "react";

import { ReactNode, useEffect } from "react";

import Loading from "../Helpers/Loading"
import { navigate } from "gatsby";
import useAuth from "../useAuth";

export default function AuthContent({ children }: { children: ReactNode }) {
  const { loggedIn, loading } = useAuth();

  // Navigate unauthenticated users to Log In page.
  useEffect(() => {
    if (!loading && !loggedIn) {
      navigate('/sign-in');
    }
  }, [loggedIn, loading, navigate]);
  

  if (loggedIn) {
    return <>{children}</>;
  }

  return <Loading></Loading>;
}