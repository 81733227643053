import { gql } from "@apollo/client";

export const GET_CUSTOMER = gql`
query GetCustomer($customerId: Int) {
  customer(customerId: $customerId) {
    firstName
    lastName
    email
    phoneNumber
    storeCredit
    loyaltyPoints
    medicalProfessionalName
    profession
    specialty
    medicalLicenceNumber
    licenceExpirationDate
    preferredCommunication
    billing {
      country
      address1
      address2
      city
      state
      postcode
      company
      phone
    }
    orders {
      nodes {
        id
        status
        total
        orderKey
        orderNumber
        dateCompleted
        date
        customerNote
        currency
        paymentMethod
        paymentMethodTitle
        modified
        subtotal
        shippingTotal
        shippingTax
        orderVersion
        datePaid
        needsPayment
        needsProcessing
        needsShippingAddress
        shippingTotal
        shipping {
          address1
          address2
          city
          company
          country
          email
          firstName
          lastName
          phone
          postcode
          state
        }
        lineItems {
          nodes {
            total
            quantity
            productId
            quantity
            total
            product {
              node {
                link
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
`;
