import * as React from "react";
import { FunctionComponent, useState, useCallback } from "react";
import AccountDetails from "../components/Account/account-details";
import * as styles from "./account-settings.module.css";
import Layout from "../components/Base/Layout";
import { Text } from "@chakra-ui/react";
import AuthContent from "../components/Login/AuthContent";
import OrderHistoryButton from "../components/Account/OrderHistoryButton";
import { SEO } from "../components/Base/Seo";
import { Helmet } from "react-helmet";
// import BillingDetails from "../components/Account/billing-details";

const AccountSettings: FunctionComponent = () => {
  return (
    <>
      <AuthContent>
        <Layout>
          <div className={styles.accountsettings}>
            <div className={styles.accountContainer}>
              <div className={styles.topCommands}>
                {/* <Text
                  textTransform="uppercase"
                  fontWeight="700"
                  className={styles.points}
                >
                  Your Points: <div className={styles.label2}>300000</div>
                </Text> */}
                <OrderHistoryButton />
              </div>
              <AccountDetails />
              {/* <BillingDetails /> */}
            </div>
          </div>
        </Layout>
      </AuthContent>
    </>
  );
};

export const Head = () => (
  <SEO
    title="Doctor Medica - Account Information"
    description="Client Account Information Page"
  />
);

export default AccountSettings;
