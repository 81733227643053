import * as React from "react";
import * as styles from "./account-details.module.css";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Link,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { gql, useMutation, useQuery } from "@apollo/client";
import useAuth, { User } from "../useAuth";

import { GET_CUSTOMER } from "../../queries/getCustomer";
import { navigate } from "gatsby";
import { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const UPDATE_PASSWORD = gql`
  mutation updatePassword($password: String!, $id: ID!) {
    updateCustomer(input: { id: $id, password: $password }) {
      customer {
        id
      }
    }
  }
`;

export default function PasswordCard() {
  
  const { loggedIn } = useAuth();

  // getting user id 
  const { user } = useAuth();
  const { id } = user as User;

  const customer = useQuery(GET_CUSTOMER, {
    variables: {id}
  })

  // const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassoword, setConfirmNewPassword] = useState("")

  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)

  const [clientErrorMessage, setClientErrorMessage] = useState('');
  const toast = useToast();

  const [resetPassword, { data, loading, error }] = useMutation(UPDATE_PASSWORD, 
    {
      onCompleted: () => {
        toast({
          title: "Your profile was successfully updated!",
          status: "success",
        });
        // window.location.reload();
      },
      onError: () => {
        toast({
          title: "Error",
          description: "There was an error updating you profile.",
          status: "error",
        });
      },
    }
)
  
  const wasPasswordReset = Boolean(data?.updateCustomer?.customer?.id);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const isValid = validate();
    if (!isValid) return

    const data:any = new FormData(event.currentTarget);
    const values = Object.fromEntries(data);

    resetPassword({
      variables: {
        id,  ...values
      },
    }).catch(error => {
      console.error(error);
    })
  }

  function validate() {
    setClientErrorMessage('');

    const isPasswordLongEnough = newPassword.length >= 8;
    if (!isPasswordLongEnough) {
      setClientErrorMessage('Password must be at least 5 characters.');
      return false;
    }

    // const doPasswordsMatch = currentPassword === newPassword;
    // if (!doPasswordsMatch) {
    //   setClientErrorMessage('Passwords must match.');
    //   return false;
    // }

    return true;
  }

  // if (wasPasswordReset) {
  //   navigate('/sign-in')
  // }

  return (
      <form method="post" onSubmit={handleSubmit} className={styles.billingDetails}>
        <b className={styles.billingDetails1}>Change password</b>
        <div className={styles.personalDetails}>
          <div className={styles.cta}>
            {/* <div className={styles.fullName}>
              <div className={styles.inputTextField}>
                <div className={styles.label}>
                  <b className={styles.fullNameContainer}>
                    <span>{`Old password `}</span>
                    <span className={styles.span}>*</span>
                  </b>
                </div>
                <InputGroup size="md">
                  <Input
                    className={styles.inputFirstname}
                    variant="onstyled"
                    
                    type={showOldPassword ? "text" : "password"}
                    name="oldPassword"
                    autoComplete="current-password"
                    id="current-password"
                    value={oldPassword}
                    onChange={(event) => setOldPassword(event.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      background="transparent"
                      _hover={{ background: "transparent" }}
                      size="sm"
                      onClick={() => setShowOldPassword(!showOldPassword)}
                    >
                      {showOldPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <div className={styles.helperMessage}>
                  <div className={styles.helperMessage1}>Helper message</div>
                </div>
              </div>
            </div> */}
          </div>
          <div className={styles.address}>
            <div className={styles.inputNumberField}>
              <div className={styles.inputTextField2}>
                <div className={styles.label}>
                  <b className={styles.fullNameContainer} id="password">
                    <span>{`New password `}</span>
                    <span className={styles.span}>*</span>
                  </b>
                </div>
                <InputGroup size="md">
                  <Input
                    className={styles.inputFirstname}
                    variant="onstyled"
                    type={showNewPassword ? "text" : "password"}
                    required
                    placeholder="Enter your password"
                    id="password"
                    name="password"
                    autoComplete="current-password"
                    value={newPassword}
                    onChange={(event) => setNewPassword(event.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      background="transparent"
                      _hover={{ background: "transparent" }}
                      size="sm"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <div className={styles.helperMessage}>
                  <div className={styles.helperMessage1}>Helper message</div>
                </div>
              </div>
            </div>
            <div className={styles.inputSelect}>
              <div className={styles.label}>
                <b className={styles.fullNameContainer}>
                  <span>{`Confirm new password `}</span>
                  <span className={styles.span}>*</span>
                </b>
              </div>
              <InputGroup size="md">
                <Input
                  className={styles.inputFirstname}
                  variant="onstyled"
                  type={showConfirmNewPassword ? "text" : "password"}
                  required
                  placeholder="Enter your password"
                  id="confirmNewPassoword"
                  name="confirmNewPassoword"
                  // autoComplete="current-password"
                  value={confirmNewPassoword}
                  onChange={(event) => setConfirmNewPassword(event.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    background="transparent"
                    _hover={{ background: "transparent" }}
                    size="sm"
                    onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                  >
                    {showConfirmNewPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <div className={styles.helperMessage}>
                <div className={styles.helperMessage1}>Helper message</div>
              </div>
            </div>
          </div>
          {clientErrorMessage ? (
            <Text color="red" className="error-message">
              {clientErrorMessage}
            </Text>
          ) : null}
          {error ? (
            <Text color="red" className="error-message">
              {error.message}
            </Text>
          ) : null}
        </div>
        <div className={styles.cta}>
          <div className={styles.cta1}>
            <button type="submit" className={styles.darkButton1}>
              <b className={styles.label10}>{loading ? "SAVING..." : "SAVE"}</b>
            </button>
          </div>
        </div>
      </form>
  );
}
